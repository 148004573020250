const page = {
    namespaced: true,
    state:{
        sites: [],
        sitename: '',
        member: {},
        // notice: {},
        board: {},
        statics: {
            totalMemCnt: 0,
            totalMemCash: 0,
            totalNormalMemCnt: 0,
            totalMemPoint: 0
        }
    },
    mutations: {

    },
    actions: {

    },
    // getters: {
    //     //카트 안에 담긴 배당내역의 총합
    //     membersCnt: function(state) {
    //         const result = state.statics.membersInfo
    //         return result.length;
    //     },
    //     membersPointSum: function(state){
    //         let result = state.statics.membersInfo;
    //         if (result.length === 0) return 0;
    //         result = state.statics.membersInfo.filter(e => e.members_type === '회원')
    //         result = result.map(e => e.members_point);
    //         result = result.reduce((prev, next) => prev+next);
    //         return result;
    //     },
    //     membersCashSum: function(state){
    //         let result = state.statics.membersInfo;
    //         console.log(result);
    //         if (result.length === 0 ) return 0;
    //         result = result.filter(e => e.members_type === '회원')
    //         result = result.map(e => e.members_cash);
    //         result = result.reduce((prev, next) => prev+next);
    //         return result;
    //     },
    // }
};

export default page
