<template>

  <section>
    <div class="wrap">
      <h1 class="page_title"><img src="@/assets/images/tab6_b.png">마일리지 정산 / 마일리지 변동 내역</h1>
      <div class="table_date">
        <div class="mileage_div">
          <input type="date" v-model="payload.startDate">
          <input type="date" v-model="payload.endDate">
          <button @click="reqData(payload)">조회</button>
        </div>
      </div>
      <div class="table_layout">
        <table class="tc">
          <thead>
          <tr>
            <th rowspan="2">번호</th>
            <th rowspan="2">처리일시</th>
            <th rowspan="2">구분</th>
            <th rowspan="2">%</th>
            <th rowspan="2">배당</th>
            <th rowspan="2">처리후 마일리지</th>
            <th rowspan="2">내용</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in lists" :key="'list' + index">
            <td>{{ index | makeIndex(payload.page, payload.size)}}</td>
            <td>{{ row.cash_done_datetime | formatDate('YYYY-MM-DD') }}<br>{{ row.cash_done_datetime | formatDate('HH:mm:ss')}}</td>
            <td
              :class="{
                'b_color': row.cash_amount > 0,
                'r_color': row.cash_amount < 0,
              }"
            >{{ row.cash_type }}</td>
            <td v-if="user.members_partner_setting.정산방식 === '루징'">
              {{ user.members_partner_setting.정산비율.기본 }}
            </td>
            <td v-else>
              {{ user.members_partner_setting.정산비율.스포츠 }}
            </td>
            <td
              :class="{
                'b_color': row.cash_amount > 0,
                'r_color': row.cash_amount < 0,
              }"
            >{{ row.cash_amount | makeComma }}원</td>
            <td class="b_color"
                :class="{
                'b_color': row.cash_amount > 0,
                'r_color': row.cash_amount < 0,
              }"
            >{{ row.cash_after | makeComma }}원</td>
            <td style="text-align: left !important;"
                  :class="{
                'b_color': row.cash_amount > 0,
                'r_color': row.cash_amount < 0,
              }"
              >
                {{ row.cash_reason }}
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <Pagination :payload="payload" :req-data="reqData" :pagination="pagination"></Pagination>
    </div>
  </section></template>

<script>
import Pagination from '@/components/Pagination';
import {mapState} from "vuex";

export default {
  name: "Main",
  components: {
    Pagination
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    })
  },
  data: function(){
    return {
      path: 'partner/profit/list',
      pagination: {},
      lists: [],
      payload: {
        page: 1,
        size:20,
        keyword: null,
        startDate: null,
        endDate: null,
        status: null,
      }
    }
  },
  mounted: function() {
    this.reqData(this.payload);
  },
  methods: {
    reqData: function(payload){
      this.$store.dispatch('auth/post', { path: this.path, data: { payload } })
          .then(e => {
            const data = e.data;
            this.lists = data.payload.list;
            this.pagination = data.payload.pagination
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
  },
}
</script>

<style scoped>

</style>
