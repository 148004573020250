<template>
  <section>
    <div class="wrap">
      <h1 class="page_title"><img src="@/assets/images/tab4_b.png">회원정보 / 하부목록</h1>


      <div class="table_layout ">
        <p class="login_user_count">전체 총판 수 : 10명 </p>
        <table class="tc">
          <thead>
          <tr>
            <th rowspan="2">번호</th>
            <th>상태</th>
            <th>사이트명</th>
            <th>타입</th>
            <th>정산방식</th>
            <th rowspan="2">하부인원</th>
            <th rowspan="2">회원수</th>
          </tr>
          <tr>
            <th>등록일</th>
            <th>가입코드</th>
            <th>아이디</th>
            <th>정산비율</th>
          </tr>
          </thead>
          <tbody>
          <template>
            <tr v-for="(row, index) in lists" :key="'partnerIndex' + index">
              <td>{{ index | makeIndex(payload.page, payload.size) }}</td>
              <td>
                {{ row.members_partner_setting.파트너상태 }}<br>
                {{ row.members_regdatetime | formatDate('YYYY-MM-DD') }}
              </td>
              <td>
                {{ row.members_sitename }}<br>
                {{ row.members_referer_code ? row.members_referer_code : '-' }}
              </td>
              <td>
                {{ row.members_partner_type }}<br>
                {{ row.members_id }}
              </td>
              <td v-if="row.members_partner_setting.정산방식 === '루징'">
                {{ row.members_partner_setting.정산방식 }}<br>
                정산비율 : <span class="percent">{{ row.members_partner_setting.정산비율.기본 }}%</span>
              </td>
              <td v-else>
                롤링<br>
                스포츠 : <span class="percent">{{ row.members_partner_setting.정산비율.스포츠 }}%</span><br>
                미니게임 : <span class="percent">{{ row.members_partner_setting.정산비율.미니게임 }}%</span> <br>
                로투스 : <span class="percent">{{ row.members_partner_setting.정산비율.로투스 }}%</span> <br>
                카지노 : <span class="percent">{{ row.members_partner_setting.정산비율.카지노 }}%</span><br>
<!--                토큰 : <span class="percent">40%</span>-->
              </td>

              <td>
                부본({{ childCnt(row.childs, "부본사") }}) 총판({{
                  childCnt(row.childs, "총판")
                }}) <br/>
                매장({{ childCnt(row.childs, "매장") }}) 딜러({{
                  childCnt(row.childs, "딜러")
                }})
              </td>
              <td>{{ row.childs.length }}명</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>

    </div>
  </section>
</template>

<script>
import moment from "moment";
// import Pagination from '@/components/Pagination';

export default {
  name: "Main",
  data: function() {
    return {
      path: "partner/member/list",
      pagination: {},
      lists: [],
      opened: [],
      payload: {
        page: 1,
        keyword: null,
        size: 20,
        startDate: null,
        endDate: null,
        type: "파트너",
      },
    };
  },
  mounted: function() {
    this.reqData(this.payload);
  },
  methods: {
    reqData: function(payload) {
      this.$store
          .dispatch("auth/post", { path: this.path, data: { payload } })
          .then((e) => {
            const data = e.data;
            this.lists = data.payload.list;
            this.pagination = data.payload.pagination;
          })
          .catch((err) => {
            alert(err.response.data.error);
          });
    },
    childCnt: function(childs, type) {
      const cnt = childs.filter((e) => e.members_partner_type === type).length;
      return cnt;
    },
    activatingChildCntForAWeek: function(childs) {
      const ago7Days = moment()
          .subtract(7, "days")
          .unix();
      const cnt = childs.filter(
          (e) => moment(e.members_recent_deposit_date).unix() >= ago7Days
      ).length;
      return cnt;
    },
    loginChildCntForAWeek: function(childs) {
      const ago7Days = moment()
          .subtract(7, "days")
          .unix();
      const cnt = childs
          .filter((e) => moment(e.members_last_login_datetime).unix() >= ago7Days)
          .filter(
              (child) => moment(child.members_recent_deposit_date).unix() < ago7Days
          ).length;
      return cnt;
    },
    joinChildCntThisMonth: function(childs) {
      const thisYear = new Date().getYear();
      const thisMonth = new Date().getMonth();
      const cnt = childs
          .filter((e) => new Date(e.members_regdatetime).getYear() == thisYear)
          .filter((e) => new Date(e.members_regdatetime).getMonth() == thisMonth)
          .length;
      return cnt;
    },
    sumChildCash: function(childs) {
      const result = childs
          .map((child) => child.members_cash)
          .reduce((acc, curr) => acc + curr, 0);
      return result;
    },
  },
}
</script>

<style scoped>

</style>
