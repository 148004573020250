import AuthService from '../../services/auth.service';
import AuthenticationService from '../../services/authentication.service';
const initialState = { status: { loggedIn: false }, user: null, stats: null };
const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        post(_, data) {
            return AuthenticationService.post(data).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    if (error) {
                        // console.log(error);
                        // commit('loginFailure');
                    }
                    return Promise.reject(error);
                }
            );
        },
        put(_, data) {
            return AuthenticationService.put(data).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    if (error) {
                        // console.log(error);
                        // commit('loginFailure');
                    }
                    return Promise.reject(error);
                }
            );
        },
        setUser({ commit }, data) {
            commit('SET_USER', data)
        },
        setStats({ commit }, data) {
            commit('SET_STATS', data)
        },
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.member = user.payload.members;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
            state.member = null;
            location.href = '/#/login';
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
            state.member = null;
            localStorage.clear();
            location.href = '/#/login';
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        SET_USER(state, user){
            state.user = user;
        },
        SET_STATS(state, data){
            state.stats = data;
        }
    }
};

export default auth

