<template>
  <section>
    <div class="wrap">
      <h1 class="page_title"><img src="@/assets/images/tab4_b.png">회원정보 / 전체회원정보</h1>
      <div class="table_date">
        <div>
          <input type="date" v-model="payload.startDate">
          <input type="date" v-model="payload.endDate">
          <select class="page_count_select" v-model="payload.size">
            <option :value="20">20개</option>
            <option :value="40">40개</option>
            <option :value="60">60개</option>
            <option :value="80">80개</option>
            <option :value="100">100개</option>
          </select>
        </div>
        <div class="search_text">
          <input type="text" placeholder="검색어" v-model="payload.keyword">
          <button @click="reqData(payload)">조회</button>
        </div>
        <div class="table_sort_btns">
<!--          <label for="type1" @click="sort('보유금')">-->
<!--            <input type="checkbox" id="type1" >-->
<!--            <p><i class="far fa-search"></i>가입날짜순</p>-->
<!--          </label>-->
          <label for="type2">
            <input type="checkbox" id="type2" :checked="payload.currentSort === '보유금' && payload.currentSortDir === 'ASC'" @click="sort('보유금')">
            <p><i class="far fa-search"></i>보유머니순</p>
          </label>
          <label for="type3">
            <input type="checkbox" id="type3" :checked="payload.currentSort === '최근접속일' && payload.currentSortDir === 'ASC'"  @click="sort('최근접속일')">
            <p><i class="far fa-search"></i>최근접속순</p>
          </label>
          <select class="page_count_select" v-model="payload.status">
            <option :value="null">전체</option>
            <option value="정상">정상</option>
            <option value="신청">신청</option>
            <option value="탈퇴">탈퇴</option>
            <option value="정상">정상</option>
            <option value="정상">정상</option>
          </select>
        </div>
      </div>

      <div class="table_layout">
        <table>
          <thead>
          <tr>
            <th rowspan="2" width="40">번호</th>
            <th>사이트명</th>
            <th>닉네임</th>
            <th>보유금</th>
            <th>총입금</th>
            <th>이달수익</th>
            <th rowspan="2">누적배팅금액</th>
            <th>최근접속</th>
          </tr>
          <tr>
            <th>회원분류</th>
            <th>아이디</th>
            <th>포인트</th>
            <th>총출금</th>
            <th>누적수익</th>
            <th>가입일시</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(member, index) in lists" :key="'member'+index">
              <td class="tc">{{ index | makeIndex(payload.page, payload.size)}}</td>
              <td>
                <p>
                  <span>{{ member.members_sitename }}</span>
                </p>
                <hr>
                <p>
                  <span>{{ member.members_type }}</span>
                </p>
              </td>
              <td :style="{'color': member.members_status === '탈퇴' ? 'red' : 'black'}">
                <div class="side">
                  <span :class="'lv'+member.members_grade">Lv{{ member.members_grade }}</span>
                  {{ member.members_nickname }}
                </div>
                <hr>
                <p>
                  <span>{{ member.members_id }}</span>
                </p>
              </td>
              <td>
                <p>
                  <span>{{ member.members_cash | makeComma }}원</span>
                </p>
                <hr>
                <p>
                  <span>{{ member.members_point | makeComma }}p</span>
                </p>
              </td>
              <td>
                <div class="side b_color">
                  <span class="r_time">{{ member.members_total_deposit_count | makeComma }}회</span>
                  {{ member.members_total_deposit_amount | makeComma }}원
                </div>
                <hr>
                <div class="side r_color">
                  <span class="b_time">{{member.members_total_cashout_count }}회</span>
                  {{ member.members_total_cashout_amount | makeComma }}원
                </div>
              </td>
              <td>
                <p>
                  <span> {{member.monthlyDepositAmount + member.monthlyWithdrawalAmount  | makeComma }}원</span>
                </p>
                <hr>
                <p>
                  <span> {{member.members_total_deposit_amount + member.members_total_cashout_amount  | makeComma }}원</span>
                </p>
              </td>
              <td> {{
                  member.members_total_crown_betting_amount +
                  member.members_total_lotus_betting_amount +
                  member.members_total_token_betting_amount +
                  member.members_total_casino_betting_amount +
                  member.members_total_minigame_betting_amount +
                  member.members_total_sports_betting_amount | makeComma
                }}원</td>
              <td>
                <p>
                  <span>{{ member.members_last_login_datetime | formatDate("YYYY-MM-DD HH:mm:ss") }}</span>
                </p>
                <hr>
                <p>
                  <span>{{ member.members_regdatetime | formatDate("YYYY-MM-DD HH:mm:ss") }}</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination :payload="payload" :req-data="reqData" :pagination="pagination"></Pagination>
    </div>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination';

export default {
  name: "List",
  components: {
    Pagination
  },
  data: function(){
    return {
      path: 'partner/member/list',
      pagination: {},
      lists: [],
      payload: {
        page: 1,
        keyword: null,
        size:20,
        startDate: null,
        endDate: null,
        currentSort: null,
        currentSortDir: 'DESC',
        type: '회원',
        status: null,
      }
    }
  },
  mounted: function() {
    this.reqData(this.payload);
  },
  methods: {
    reqData: function(payload){
      this.$store.dispatch('auth/post', { path: this.path, data: { payload } })
          .then(e => {
            const data = e.data;
            this.lists = data.payload.list;
            this.pagination = data.payload.pagination
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    sort: function(sortName){
      console.log(sortName, this.payload);
      if(sortName === this.payload.currentSort) {
        this.payload.currentSortDir = this.payload.currentSortDir === 'DESC' ? 'ASC' : 'DESC';
      } else this.payload.currentSortDir = 'DESC';
      this.payload.currentSort = sortName;
      this.reqData(this.payload);
    },
  },
}
</script>

<style scoped>

</style>
