<template>
  <section>
    <div class="container">
        <h1>거래 목록</h1>
        <div class="filter-section">
            <!-- <input type="date" value="2024-09-30"> -->
            <!-- <select>
                <option value="18:30">18:30</option>
            </select>
            <select>
                <option value="18:40">18:40</option>
            </select> -->
            <select v-model="payload.status">
                <option :value="null">=== 상태 ===</option>
                <option value="upcoming">대기중</option>
                <option value="completed">처리완료</option>
            </select>
            <select v-model="payload.type">
                <option :value="null">=== 상태 ===</option>
                <option value="GOLD">금거래</option>
                <option value="NASDAQ">나스닥</option>
                <option value="S&P500">S&P500</option>
                <option value="WTI">WTI</option>
            </select>
            <!-- <input type="text" placeholder="검색"> -->
            <button @click="reqData">검색</button>
        </div>
        <table>
            <thead>
                <tr>
                    <!-- <th><input type="checkbox"></th> -->
                    <th>번호</th>
                    <th>구분</th>
                    <th>종목</th>
                    <th>거래회차</th>
                    <th>거래시간</th>
                    <th>배당</th>
                    <th>예상결과</th>
                    <th>거래결과</th>
                    <th>진행상태</th>
                    <th>배팅</th>
                    <th>관리</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="game in lists" :key="'game'+game.seq">
                    <!-- <td><input type="checkbox"></td> -->
                    <td>{{ game.seq }}</td>
                    <td>{{ game.type }}</td>
                    <td>3분거래</td>
                    <td>{{ game.round }}회차</td>
                    <td>{{ game.endTime | formatDate('YYYY-MM-DD HH:mm:ss')}}</td>
                    <td>1.95</td>
                    <td v-if="game.expectResult">{{ game.expectResult === 'under' ? '매도 (SELL)' : '매수 (BUY)' }}</td>
                    <td v-else></td>
                    <td v-if="game.result">{{ game.result === 'under' ? '매도 (SELL)' : '매수 (BUY)' }}</td>
                    <td v-else></td>
                    <td>{{ game.status === 'completed' ? '거래종료' : '대기중'}}</td>
                    <td>({{ game.betCount | makeComma }}회) {{ game.betAmount | makeComma }}<br>[매수] {{ game.underBetAmt | makeComma }} : [매도] {{ game.overBetAmt | makeComma }}</td>
                    <td class="action-buttons" v-if="game.status === 'upcoming'">
                        <button class="buy" @click="modifyExpectResult(game, 'over')">매수</button>
                        <button class="sell" @click="modifyExpectResult(game, 'under')">매도</button>
                    </td>
                    <td v-else></td>
                </tr>
            </tbody>
        </table>
        <Pagination :payload="payload" :req-data="reqData" :pagination="pagination"></Pagination>
        <!-- <div class="control-buttons">
            <button>체크 &gt; 등록대기</button>
            <button>체크 &gt; 등록</button>
            <button>체크 &gt; 취소</button>
            <button>체크 &gt; 삭제</button>
        </div>
        <div class="pagination">
            <button>처음</button>
            <button>이전</button>
            <button>다음</button>
            <button>마지막</button>
        </div> -->
    </div>
  </section>
</template>

<script>

import Pagination from '@/components/Pagination';

export default {
  name: "GameList",
  components: {
    Pagination,
  },
  data: function(){
    return {
      path: 'partner/game/list',
      pagination: {},
      lists: [],
      payload: {
        page: 1,
        type: null,
        status: 'upcoming',
        size:20,
      }
    }
  },
  mounted: function() {
    this.reqData(this.payload);
  },
  methods: {
    reqData: function(payload){
      this.$store.dispatch('auth/post', { path: this.path, data: { payload } })
          .then(e => {
            const data = e.data;
            this.lists = data.payload.list;
            this.pagination = data.payload.pagination
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    //모달 오픈
    openModal: function(modalName, data=null) {
      this.$store.dispatch('modals/openModal', {name: modalName, data: { user: data }})
    },
    modifyExpectResult: function(game, expectResult){
      if (!confirm('해당 거래의 예상결과를 변경하시겠습니까?')) return;
      game.expectResult = expectResult;
      this.$store.dispatch('auth/put', { path: 'partner/game/update', data: { payload: { game } } })
          .then(e => {
            const data = e.data;
            if (data.success) alert('수정되었습니다.');
            this.reqData(this.payload);
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
  },
}
</script>

<style scoped>
body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 20px;
}
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
h1 {
    font-size: 24px;
    margin-bottom: 20px;
}
.filter-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.filter-section input,
.filter-section select {
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.filter-section button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}
table th, table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
}
table th {
    background-color: #f2f2f2;
}
.control-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.control-buttons button {
    padding: 8px 16px;
    margin: 0 5px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.pagination {
    display: flex;
    justify-content: center;
}
.pagination button {
    padding: 8px 16px;
    margin: 0 5px;
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}
.action-buttons {
    display: flex;
    justify-content: center;
}
.action-buttons button {
    padding: 8px 16px;
    margin: 0 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.action-buttons .buy {
    background-color: #ff4d4d;
    color: #fff;
}
.action-buttons .sell {
    background-color: #007bff;
    color: #fff;
}
</style>