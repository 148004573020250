<template>
  <!-- Layout -->
  <div>
    <Header :toggle-menu="toggleMenu" :is-menu="isMenu"></Header>
    <div class="body" :class="{'open': isMenu}">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
export default {
  name: "Layout",
  data: () => {
    return {
      isMenu: false
    }
  },
  components: {
    Header
  },
  watch: {
    $route(){
      this.isMenu = false
    }
  },
  methods:{
    toggleMenu(){
      this.isMenu = !this.isMenu;
    }
  }
}
</script>
