import Vue from 'vue';
import * as momentTemp from 'moment';

// 숫자 천단위에 , 찍기
Vue.filter('makeComma', (str) => {
    if (str === null) return 0;
    return String(Math.floor(str)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

// 글자 10 글자 이상시 ... 말줄임 처리
Vue.filter('substring',(str) => {
    let maxLength = 10;
    if (str.length < maxLength) {
        return str;
    }
    return str.substring(0, maxLength) + '...';
})

// 베팅상태값 표시
Vue.filter('bettingStatus', (str) => {
    let msg = '';
    if (str === 'exception'){
        msg = '적중특례'
    }

    if (str === 'wait'){
        msg = '대기'
    }

    if (str === 'hit'){
        msg = '당첨'
    }

    if (str === 'miss'){
        msg = '낙첨'
    }

    return msg;
})

Vue.filter('sportsStatus', (str) =>{
    let msg = '';
    if (str === 'exception'){
        msg = '적중특례'
    }

    if (str === 'wait'){
        msg = '대기'
    }

    if (str === 'hit'){
        msg = '승'
    }

    if (str === 'miss'){
        msg = '패'
    }

    return msg;
});

Vue.filter('formatDate',(value, format)=>{
    if (value === null || value === undefined || format === undefined) {
        return 'X'
    }
    if (format === 'from') {
        return momentTemp(value).fromNow()
    }
    return momentTemp(value).format(format)
})


Vue.filter('subtractDate',(after, before)=>{
    if (after === null || after === undefined || after === undefined) {
        return ''
    }
    let beforeDay = momentTemp(before);
    let afterDay = momentTemp(after);

    let gap = momentTemp.duration(afterDay.diff(beforeDay))
    return `${gap.hours()}:${gap.minutes()}:${gap.seconds()}`
})

// 베팅상태값 표시
Vue.filter('cashType', (str) => {
    let msg = '';
    if (str === '입금'){
        msg = '적중특례'
    }

    if (str === 'wait'){
        msg = '대기'
    }

    if (str === 'hit'){
        msg = '당첨'
    }

    if (str === 'miss'){
        msg = '낙첨'
    }

    return msg;
})


Vue.filter('makeIndex', (index, page, size) => {
    return ((page - 1) * size) + (index+1)
})

Vue.filter('sportsName', (sport) => {
    if (sport){
        return sport.sports_name_kr1 ? sport.sports_name_kr1 : sport.sports_name_en
    }
    return ''
})

Vue.filter('locationName', (location) => {
    if (location){
        return location.locations_name_kr1 ? location.locations_name_kr1 : location.locations_name_en
    }
    return ''
})

Vue.filter('leagueName', (league) => {
    if (league){
        return league.leagues_name_kr1 ? league.leagues_name_kr1 : league.leagues_name_en
    }
    return ''
})

Vue.filter('teamName', (team) => {
    if (team){
        return team.teams_name_kr1 ? team.teams_name_kr1 : team.teams_name_en
    }
    return ''
})

Vue.filter('bookmakerName', (bookmaker) => {
    if (bookmaker){
        return bookmaker.bookmakers_name_kr1 ? bookmaker.bookmakers_name_kr1 : bookmaker.bookmakers_name_en
    }
    return ''
})

Vue.filter('marketName', (market) => {
    if (market){
        return market.markets_name_kr1 ? market.markets_name_kr1 : market.markets_name_en
    }
    return ''
})

Vue.filter('totalBettingStatus', (betting) => {
    let name = '';
    if (betting.betting_status.indexOf('취소') >= 0) return '취소';
    if (betting.betting_total_result === 'hit') name = '당첨';
    if (betting.betting_total_result === 'miss') name = '낙첨';
    if (betting.betting_total_result === 'exception') name = '적특';
    if (betting.betting_total_result === 'wait') name = '대기';
    return name;
})

Vue.filter('betsName',(betting) => {
    if (betting.betting_bet_name=== '1') {
        let name =  `${betting.betting_game_home_name_kr}`;
        if (betting.betting_line && betting.betting_line !== 'null') name += ` (${betting.betting_line})`
        return name;
    }
    if (betting.betting_bet_name === 'X') return '무';
    if (betting.betting_bet_name === '2') {
        let name = `${betting.betting_game_away_name_kr}`
        if (betting.betting_line && betting.betting_line !== 'null') name += ` (${betting.betting_line})`
        return name;
    }
    if (betting.betting_bet_name=== 'W1') {
        let name =  `${betting.betting_game_home_name_kr || betting.betting_game_home_name_en}`;
        return name;
    }
    if (betting.betting_bet_name === 'W2') {
        let name =  `${betting.betting_game_away_name_kr || betting.betting_game_away_name_en}`;
        return name;
    }
    if (betting.betting_bet_name=== 'Home') {
        let name =  `${betting.betting_game_home_name_kr || betting.betting_game_home_name_en}`;
        name += ` (${betting.betting_line})`
        return name;
    }
    if (betting.betting_bet_name === 'Away') {
        let name =  `${betting.betting_game_away_name_kr || betting.betting_game_away_name_en}`;
        name += ` (${betting.betting_line})`
        return name;
    }
    if (betting.betting_bet_name === 'Over') return `오버 ${betting.betting_line}`;
    if (betting.betting_bet_name === 'Under') return `언더 ${betting.betting_line}`;
    if (betting.betting_bet_name === '1X') return '홈승 또는 무승부';
    if (betting.betting_bet_name === '12') return '홈승 또는 원정승';
    if (betting.betting_bet_name === 'X2') return '무승부 또는 원정승';
    if (betting.betting_bet_name === 'Odd') return '홀';
    if (betting.betting_bet_name === 'Even') return '짝';

    if (betting.betting_bet_name?.indexOf('Team1') >= 0) return  betting.betting_bet_name.replace('Team1', `${betting.betting_game_home_name_kr || betting.betting_game_home_name_en}`);
    if (betting.betting_bet_name?.indexOf('Team2') >= 0) return  betting.betting_bet_name.replace('Team2', `${betting.betting_game_away_name_kr || betting.betting_game_away_name_en}`);

    if (betting.betting_bet_name === 'NoGoal') return '노골';

    if (betting.betting_bet_name?.indexOf('W1andOver') >= 0) {
        let name = betting.betting_bet_name.replace('W1andOver', `${ betting.betting_game_home_name_kr || betting.betting_game_home_name_en } & 오버`)
        return name;
    }
    if (betting.betting_bet_name?.indexOf('W2andOver') >= 0) {
        let name = betting.betting_bet_name.replace('W2andOver', `${ betting.betting_game_away_name_kr || betting.betting_game_away_name_en } & 오버`);
        return name;
    }
    if (betting.betting_bet_name?.indexOf('12andOver') >= 0) {
        let name = betting.betting_bet_name.replace('12andOver', '12 & 오버')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('W1andUnder') >= 0) {
        let name = betting.betting_bet_name.replace('W1andUnder', `${betting.betting_game_home_name_kr || betting.betting_game_home_name_en} & 언더`)
        return name;
    }
    if (betting.betting_bet_name?.indexOf('W2andUnder') >= 0) {
        let name = betting.betting_bet_name.replace('W2andUnder', `${betting.betting_game_away_name_kr || betting.betting_game_away_name_en} & 언더`)
        return name;
    }
    if (betting.betting_bet_name?.indexOf('12andUnder') >= 0) {
        let name = betting.betting_bet_name.replace('12andUnder', '12 & 언더')
        return name;
    }

    if (betting.betting_bet_name?.indexOf('1XandOver') >= 0) {
        let name = betting.betting_bet_name.replace('1XandOver', '1X & 오버')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('XandOver') >= 0) {
        let name = betting.betting_bet_name.replace('XandOver', '무 & 오버')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('X2andOver') >= 0) {
        let name = betting.betting_bet_name.replace('X2andOver', 'X2 & 오버')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('1XandUnder') >= 0) {
        let name = betting.betting_bet_name.replace('1XandUnder', '1X & 언더')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('XAndUnder') >= 0) {
        let name = betting.betting_bet_name.replace('XAndUnder', '무 & 언더')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('X2andUnder') >= 0) {
        let name = betting.betting_bet_name.replace('X2andUnder', 'X2 & 언더')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('ormore') >= 0) {
        let name = betting.betting_bet_name.replace('ormore', '골 또는 그이상')
        return name;
    }
    return betting.betting_bet_name
})
