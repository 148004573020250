const getDefaultState = () => {
    return {
        msg:{
            route: '',
            method: '',
            payload: {},
        }
    }
}
const state = getDefaultState();

const req = {
    state,
};

export default req
