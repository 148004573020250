const masks = {
    namespaced: true,
    state:{
        input: 'YYYY-MM-DD',
    },
    mutations: {

    },
    actions: {

    },
    getters: {

    }
};

export default  masks
