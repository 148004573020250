const modals = {
    namespaced: true,
    state: {
        confirm: false,
        userDetail: false,
        userRegister: false,
        partnerRegister: false,
        partnerCalcRatio: false,
        partnerMoneyTransfer: false,
        ipInfo: false,
        couponRegister: false,
        couponDelete: false,
        bettingList: false,
        boardContent: false,
        boardNoticeRegister: false,
        boardPopupRegister: false,
        boardEventRegister: false,
        boardUpperCaseRegister: false,
        boardFreeRegister: false,
        boardReplyRegister: false,
        boardFAQRegister: false,
        boardSpotRegister: false,
        boardCategory1Register: false,
        boardCategory2Register: false,
        gameRegister: false,
        marketRegister: false,
        gameDetail: false,
        leagueRegister: false,
        partnerDWDetail: false,
        partnerRollingDetail: false,
    },
    mutations: {},
    actions: {  },
    getters: {  }
};

export default modals
