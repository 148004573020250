import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'partner/auth/login', user)
            .then(response => {
                return response.data;
            })
    }
    logout() {
        localStorage.removeItem('token');
    }
    register(user) {
        return axios.post(API_URL + 'signup', user);
    }
}
export default new AuthService();
