import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

// 추가 스토어
import modals from './modules/modals';
import page from './modules/page';
import socket from './modules/socket';
import masks from './modules/masks';
import req from './modules/req';
import auth from './modules/auth';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    modals,
    page,
    socket,
    masks,
    req,
    auth
  },
  plugins: [
      createPersistedState({
        paths: ['page', 'masks', 'req', 'auth'],
      })
  ],
  strict: false
})


