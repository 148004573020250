import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_API_URL;
class AuthenticationService {
    post({ path, data = {} }) {
        return axios.post(API_URL + path, data, { headers: authHeader() });
    }

    put({ path, data = {} }) {
        return axios.put(API_URL + path, data, { headers: authHeader() });
    }

    delete({ path, data = {} }) {
        return axios.delete(API_URL + path, data, { headers: authHeader() });
    }
}
export default new AuthenticationService();
