<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  beforeCreate(){
    console.log(this.$route.query.token);
    if (this.$route.query.token) localStorage.setItem('token', JSON.stringify({ jwt_token: this.$route.query.token }));
  },
}
</script>

<style>
  @import "https://pro.fontawesome.com/releases/v5.13.0/css/all.css";
</style>
