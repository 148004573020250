<template>
  <div>
    <header>
      <div class="wrap">
        <div class="menu_header_div">
          <transition name="fade">
            <button class="side_menu_btn" :class="{'open': isMenu }" @click="toggleMenu"></button>
          </transition>
          <h1><span>거래소</span> 세일즈</h1>
        </div>
        <a style="cursor: pointer;" @click="$store.dispatch('auth/logout')">로그아웃</a>
      </div>
    </header>
    <div class="side_menu_wrap" :class="{'open': isMenu }">
      <dl>
        <dt>{{ user.members_nickname }}</dt>
        <dd>
          회원 총 보유금
          <span v-if="stats">{{ stats.membersBalance || 0 | makeComma }}원</span>
        </dd>
        <dd>
          보유 마일리지
          <span style="color:#c67e29">{{ user.members_cash | makeComma }}원</span>
        </dd>
      </dl>
      <ul class="side_menu_ul">
        <li>
          <router-link to="/term" class="dep1"><span class="menu_icon"><img src="@/assets/images/tab1.png"></span>종합 통계</router-link>
        </li>
        <li>
          <div class="dep1" style="cursor: pointer;" @click="toggleSubmenu('입출금')">
            <p><span class="menu_icon"><img src="@/assets/images/tab2.png"></span>입출금 목록</p>
            <i class="far fa-chevron-down"></i>
          </div>
          <ul class="dep2" style="display: block;" v-if="expandedSubmenu.indexOf('입출금')>=0">
            <li><router-link to="/member/deposit">입금목록</router-link></li>
            <li><router-link to="/member/withdrawal">출금목록</router-link></li>
          </ul>
        </li>
        <!-- <li>
          <router-link to="/member/casino_dw" class="dep1"><span class="menu_icon"><img src="@/assets/images/tab3.png"></span>카지노 충환목록</router-link>
        </li> -->
        <li>
          <div class="dep1" style="cursor: pointer;" @click="toggleSubmenu('회원정보')">
            <p><span class="menu_icon"><img src="@/assets/images/tab4.png"></span>회원정보</p>
            <i class="far fa-chevron-down"></i>
          </div>
          <ul class="dep2" style="display: block;" v-if="expandedSubmenu.indexOf('회원정보')>=0">
            <li><router-link to="/member/list">전체회원 목록</router-link></li>
            <li><router-link to="/member/active">현재 접속자 목록</router-link></li>
            <li><router-link to="/member/child">하부 목록</router-link></li>
          </ul>
        </li>
        <li>
          <router-link to="/member/betting" class="dep1">
            <span class="menu_icon">
              <img src="@/assets/images/tab5.png">
            </span>회원 베팅 현황
          </router-link>
          <router-link to="/member/game_list" class="dep1">
            <span class="menu_icon">
              <img src="@/assets/images/tab5.png">
            </span>회원 경기 베팅 현황
          </router-link>
        </li>
        <li>
          <div class="dep1" style="cursor: pointer;" @click="toggleSubmenu('마일리지')">
            <p><span class="menu_icon"><img src="@/assets/images/tab6.png"></span>마일리지 정산</p>
            <i class="far fa-chevron-down"></i>
          </div>
          <ul class="dep2" style="display: block;" v-if="expandedSubmenu.indexOf('마일리지')>=0">
            <li><router-link to="/req/withdrawal">마일리지 출금 신청</router-link></li>
            <li><router-link to="/cash_log">마일리지 변동 내역</router-link></li>
          </ul>
        </li>
      </ul>

      <table v-if="stats">
        <tbody>
        <tr>
          <th>회원수</th>
          <td>{{ stats.memberCnt || 0 }}명</td>
        </tr>
        <tr>
          <th>실 회원수</th>
          <td>{{ stats.activeMemberCnt || 0 }}명</td>
        </tr>
        <tr>
          <th>회원 총 보유금</th>
          <td>{{ stats.membersBalance || 0 | makeComma }}원</td>
        </tr>
        <tr>
          <th>배팅진행중</th>
          <td>{{ stats.progressBet || 0 | makeComma }}</td>
        </tr>
        <tr>
          <th>예상당첨금</th>
          <td>{{ stats.expectedAmt || 0 | makeComma }}원</td>
        </tr>
        <tr>
          <th>보유 마일리지</th>
          <td>{{ user.members_cash || 0 | makeComma }}원</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "Header",
  props: ['isMenu', 'toggleMenu'],
  data: () => {
    return {
      expandedSubmenu: '',
    }
  },
  created() {
    this.reqData()
    setInterval(this.reqData, 20000);
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      stats: state => state.auth.stats,
    })
  },
  methods: {
    toggleSubmenu: function(menuName) {
      if(this.expandedSubmenu.indexOf(menuName)>=0) this.expandedSubmenu = this.expandedSubmenu.replace(menuName,'');
      else this.expandedSubmenu += menuName;
    },
    reqData: function(){
      this.$store.dispatch('auth/post', { path: 'partner/statics' })
          .then(e => {
            const data = e.data;
            this.$store.dispatch('auth/setUser', data.payload.user);
            delete data.payload.user;
            this.$store.dispatch('auth/setStats', data.payload);
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
  },
}
</script>

<style scoped>

</style>
