<template>
  <section>
    <div class="wrap">
      <h1 class="page_title"><img src="@/assets/images/tab4_b.png">회원정보 / 전체회원정보</h1>


      <div class="table_layout ">
        <p class="login_user_count">총 접속자수 : 10명 </p>
        <table class="tc">
          <thead>
          <tr>
            <th>회원아이디</th>
            <th>닉네임</th>
            <th>보유금</th>
            <th>접속주소</th>
            <th>현재위치</th>
            <th>접속기기</th>
            <th>접속일자</th>
          </tr>
          </thead>
          <tbody>
<!--          <tr>-->
<!--            <td>test1</td>-->
<!--            <td>테스트1</td>-->
<!--            <td>0원</td>-->
<!--            <td>www.www.com</td>-->
<!--            <td>베팅내역</td>-->
<!--            <td>PC</td>-->
<!--            <td>2023-02-16<br>20:37:31</td>-->
<!--          </tr>-->
          </tbody>
        </table>
      </div>

    </div>
  </section>
</template>

<script>
// import Pagination from '@/components/Pagination';

export default {
  name: "Main",
  // components: {
  //   Pagination
  // },

}
</script>

<style scoped>

</style>
