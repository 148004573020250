<template>
  <section>
    <div class="wrap">
      <h1 class="page_title"><img src="@/assets/images/tab6_b.png">마일리지 정산 / 마일리지 출금신 신청</h1>

      <p class="notice_text">
        주의사항
        세일즈 가입시 등록하신 입출금 전용 계좌로만 출금가능합니다.<br>
        출금신청시 가능한 <span>최소 금액은 10,000원 이상</span> 가능하며,<br>
        금액단위는 <span>10,000원 단위</span> 로만 신청 가능합니다.
      </p>

      <div class="table_layout" style="margin-bottom: 20px;">
        <table class="text_left">
          <tbody>
          <tr>
            <th>보유 마일리지</th>
            <td>{{ user.members_cash | makeComma }}</td>
          </tr>
          <tr>
            <th>출금 예금주</th>
            <td>{{ user.members_cashout_owner }}</td>
          </tr>
          <tr>
            <th>출금 은행명</th>
            <td>{{ user.members_cashout_bankname }}</td>
          </tr>
          <tr>
            <th>출금 계좌번호</th>
            <td>{{ user.members_cashout_account }}</td>
          </tr>
          <tr>
            <th>출금액</th>
            <td>
              <div class="service_input">
                <input type="text" v-model="payload.amount">
                <button @click="requestWithdrawal">출금</button>
              </div>
              <div class="mon_btn_div">
                <button @click="addAmount(10000)">1만원</button>
                <button @click="addAmount(50000)">5만원</button>
                <button @click="addAmount(100000)">10만원</button>
                <button @click="addAmount(200000)">20만원</button>
                <button @click="addAmount(500000)">50만원</button>
                <button @click="resetAmount">지우기</button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="table_date">
        <div>
          <input type="date" v-model="payload.startDate">
          <input type="date" v-model="payload.endDate">
          <select class="page_count_select" v-model="payload.size">
            <option value="20">20개</option>
            <option value="40">40개</option>
            <option value="60">60개</option>
            <option value="80">80개</option>
            <option value="100">100개</option>

          </select>
        </div>
        <div class="search_text">
          <input type="text" placeholder="검색어" v-model="payload.keyword">
          <button @click="reqData2(payload)">조회</button>
        </div>
      </div>
      <div class="table_layout">
        <table class="tc">
          <thead>
          <tr>
          <tr>
            <th>번호</th>
            <th>예금주</th>
            <th>은행명</th>
            <th>계좌번호</th>
            <th>금액</th>
            <th>처리일시</th>
            <th>상태</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in lists" :key="'withdrawal'+index">
            <td>{{ index | makeIndex(payload.page, payload.size)}}</td>
            <td>{{ row.cash_ownername }}</td>
            <td>{{ row.cash_bankname }}</td>
            <td>{{ row.cash_account }}</td>
            <td>{{ row.cash_amount | makeComma }}원</td>
            <td>{{ row.cash_done_datetime | formatDate('YYYY-MM-DD') }}<br>{{ row.cash_done_datetime | formatDate('HH:mm:ss') }}</td>
            <td>{{ row.cash_status }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <Pagination :payload="payload" :req-data="reqData2" :pagination="pagination"></Pagination>

    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";
import Pagination from '@/components/Pagination';

export default {
  name: "Main",
  components: {
    Pagination
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    })
  },
  data: function(){
    return {
      pagination: null,
      lists: null,
      payload: {
        amount: 0,
        page: 1,
        size: 20,
        detail_type: '캐시',
        type: '출금',
        startDate: null,
        endDate: null,
        keyword: null,
      }
    }
  },
  mounted: function(){
    this.reqData()
    this.reqData2(this.payload)
  },
  methods: {
    addAmount: function(amount){
      this.payload.amount += amount
    },
    resetAmount: function(){
      this.payload.amount = 0;
    },
    reqData: function(){
      this.$store.dispatch('auth/post', { path: 'partner/info' })
          .then(e => {
            const data = e.data;
            this.$store.dispatch('auth/setUser', data.payload);
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    reqData2: function(payload){
      this.$store.dispatch('auth/post', { path: 'partner/balance/list2', data: { payload } })
          .then(e => {
            const data = e.data;
            // console.log(data)
            this.lists = data.payload.list;
            this.pagination = data.payload.pagination
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    requestWithdrawal: function(){
      if (this.payload.amount < 10000) return alert('10,000원 이상 신청가능합니다.');
      if (confirm('출금신청 하시겠습니까?')){
        this.$store.dispatch('auth/post', { path: 'partner/request/withdrawal', data: { payload: this.payload } })
            .then(e => {
              const data = e.data;
              if (data.success){
                this.reqData()
                this.resetAmount()
              }
              alert(data.msg)
            })
            .catch(err => {
              alert(err.response.data.error);
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
