import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './helper/plugins'
import './helper/filters'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from "vue-cookies";

/* import the fontawesome core */
// import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'



Vue.config.productionTip = false

import './assets/css/default.css'
import './assets/css/main.css'
// import './assets/css/font-awesome.css'
// import './assets/css/owl.carousel.css'
// import './assets/css/owl.theme.default.css'

// Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueAxios, axios)
Vue.use(VueCookies);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')



