<template>
  <section>
    <div class="container">
        <div class="filter-container">
            <input type="date" value="2024-09-30">
            <input type="date" value="2024-09-30">
            <select>
                <option>=== 상태 ===</option>
            </select>
            <select>
                <option>=== 종목 ===</option>
            </select>
            <input type="text" placeholder="검색">
            <button class="btn btn-blue">검색</button>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>회원명(회원아이디)</th>
                        <th>구매시간</th>
                        <th>마감첨차</th>
                        <th>종목</th>
                        <th>구매금액</th>
                        <th>예상금액</th>
                        <th>적중금액</th>
                        <th>거래 후 보유금액</th>
                        <th>결과</th>
                        <!-- <th>관리</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="betting in lists" :key="'betting'+betting.betting_seq">
                        <td>{{ betting.member.members_nickname }} ({{ betting.member.members_id }})</td>
                        <td>{{ betting.betting_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss')}}</td>
                        <td>{{ betting.betting_round }} 회차</td>
                        <td>{{ betting.betting_game_market_name_kr }} <span class="btn btn-red" v-if="betting.betting_side === 'over'">롱</span><span class="btn btn-blue" v-else>숏</span></td>
                        <td>
                            <span class="btn btn-blue" @click="betting.betting_bet_amount = Number(betting.betting_bet_amount) * 10">X10</span> 
                            <span class="btn btn-blue"  @click="betting.betting_bet_amount = betting.member.members_cash">MAX</span> 
                            <input type="text" v-model="betting.betting_bet_amount">
                            <span class="btn btn-red" @click="changeAmount(betting)">변경</span>
                        </td>
                        <td>{{ betting.betting_expected_prize | makeComma}}</td>
                        <td>{{ betting.betting_payed_amount | makeComma}}</td>
                        <td>{{ betting.member.members_cash | makeComma }}</td>
                        <td><span class="btn btn-blue" v-if="betting.betting_total_result === 'hit'">실현</span><span class="btn btn-red" v-else>실패</span></td>
                        <!-- <td><span class="btn btn-gray">취소</span></td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </section>
</template>

<script>
//import Pagination from '@/components/Pagination';

export default {
  name: "Betting",
  components: {
    //Pagination
  },
  data: function(){
    return {
      path: 'partner/betting/list',
      pagination: {},
      lists: [],
      opened: [],
      payload: {
        page: 1,
        keyword: null,
        size:20,
        startDate: null,
        endDate: null,
        status: null,
        type: null,
      }
    }
  },
  mounted: function() {
    this.reqData(this.payload);
  },
  methods: {
    reqData: function(payload){
      this.$store.dispatch('auth/post', { path: this.path, data: { payload } })
          .then(e => {
            const data = e.data;
            this.lists = data.payload.list;
            this.pagination = data.payload.pagination
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    toggle: function (id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    changeAmount(betting) {
        if (!confirm('해당금액을 변경하시겠습니까?')) return;
        this.$store.dispatch('auth/put', { path: 'partner/betting/changeAmount', data: { payload: { bettingSeq: betting.betting_seq, amount: betting.betting_bet_amount} } })
          .then(e => {
            const data = e.data;
            if (data.success) alert('변경되었습니다.');
            this.reqData(this.payload);
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    }
  },
}
</script>

<style scoped>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f5f5f5;
        }
        .container {
            width: 100%;
            padding: 20px;
        }
        .table-container {
            overflow-x: auto;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            background-color: #fff;
        }
        th, td {
            padding: 10px;
            text-align: center;
            border: 1px solid #ddd;
        }
        th {
            background-color: #2c6e49;
            color: #fff;
        }
        .filter-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        .filter-container input, .filter-container select {
            padding: 5px;
            margin-right: 10px;
        }
        .btn {
            padding: 5px 10px;
            border: none;
            cursor: pointer;
        }
        .btn-blue {
            background-color: #007bff;
            color: #fff;
        }
        .btn-red {
            background-color: #dc3545;
            color: #fff;
        }
        .btn-green {
            background-color: #28a745;
            color: #fff;
        }
        .btn-gray {
            background-color: #6c757d;
            color: #fff;
        }
</style>
