import Vue from 'vue'
import VueRouter from 'vue-router'


// 레이아웃 페이지
import Login from '../Login.vue' // 로그인 화면
import Layout from '../Layout.vue' // 로그인후 레이아웃 화면
import Term from '../pages/Statics/Term.vue' // 종합통계
import CashLog from '../pages/Calc/Log.vue' // 캐시로그
import Withdrawal from '../pages/Calc/Withdrawal.vue' // 출금신청

import MemberDeposit from '../pages/Member/Deposit.vue' // 회원입금내역
import MemberWithdrawal from '../pages/Member/Withdrawal.vue' // 회원출금내역
import MemberCasino from '../pages/Member/Casino.vue' // 카지노
import MemberBetting from '../pages/Member/Betting.vue' // 베팅내역
import GameList from '../pages/Member/GameList.vue' // 경기리스트
import MemberList from '../pages/Member/List.vue' // 회원내역
import MemberChild from '../pages/Member/Child.vue' // 회원내역
import MemberActive from '../pages/Member/Active.vue' // 회원내역

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    // console.log('RELOAD location >> ', location)
    return window.location.reload();
  });
};


Vue.use(VueRouter)
// 라우팅 정의
const routes = [
  {path:'/login', component:Login}, // 로그인 화면
  {path:'/term', component:Layout, props:{currentMenu:'main'}, children:[{path:"/", component: Term
  }]}, // 대쉬보드
  {path:'/cash_log', component:Layout, props:{currentMenu:'cash_log'}, children:[{path:"/", component: CashLog}]}, // 대쉬보드
  {path:'/req', component:Layout, props:{currentMenu:'request'}, children:[{path:"withdrawal", component: Withdrawal}]}, // 대쉬보드

  {
    path:'/member', component:Layout,
    props:{currentMenu:'member'},
    children:[
      { path:"deposit", component: MemberDeposit },
      { path:"withdrawal", component: MemberWithdrawal },
      { path:"casino_dw", component: MemberCasino },
      { path:"betting", component: MemberBetting },
      { path:"game_list", component: GameList },
      { path:"list", component: MemberList },
      { path:"child", component: MemberChild },
      { path:"active", component: MemberActive },
    ]
  }, // 대쉬보드

  //
  // // 회원관리
  // {
  //   path:'/user',
  //   component:Layout,
  //   props:{currentMenu:'회원목록'},
  //   children:[
  //     {path:"list", component:pageUserList},
  //     {path:"active", component:pageUserActive},
  //     {path:"partner", component:pagePartnerList}
  //   ]}, // 회원관리-회원목록
  //
  // // 입출금관리
  // {
  //   path:'/balance',
  //   component:Layout,
  //   props:{currentMenu:'입출금목록'},
  //   children:[
  //     {path:"list", component:pageBalanceList},
  //     {path:"deposit", component:pageBalanceDeposit},
  //     {path:"withdrawal", component:pageBalanceCashout},
  //   ]
  // },
  //
  // // 입출금관리
  // {
  //   path:'/profit',
  //   component:Layout,
  //   props:{currentMenu:'마일리지 관리'},
  //   children:[
  //     {path:"list", component:pageProfitList},
  //   ]
  // },
  //
  //
  // // 베팅관리
  // {path:'/betting',
  //   component:Layout,
  //   props:{currentMenu:'베팅내역'},
  //   children:[
  //       {path:"list", component:pageBettingList}
  //   ]}, // 베팅관리-베팅내역
  //
  // // 정산관리
  // {
  //   path:'/calc',
  //   component:Layout,
  //   props:{currentMenu:'기간별정산'},
  //   children:[
  //     {path:"term", component:pageCalculateTerm},
  //     {path:"game", component:pageCalculateGame},
  //     // {path:"/casino", component:pageCalculateTerm}
  //
  //   ]
  // },
  //
  // // 정산관리
  // {
  //   path:'/request',
  //   component:Layout,
  //   props:{currentMenu:'출금신청'},
  //   children:[
  //     {path:"withdrawal", component:pageRequestWithdrawal},
  //
  //   ]
  // },

  {path: '/*', redirect: '/login'}
]

const router = new VueRouter({
//  mode: "history",
  routes
})

export default router
