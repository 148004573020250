import Vue from 'vue';

const getDefaultState = () => {
    return {
        isConnected: false,
        message: {},
        reconnectError: false,
        loginStatus: !!localStorage.hash,
        hash: localStorage.hash
    }
}

const state = getDefaultState();

// const actions = {
//     // 로그인
//     LOGIN: function(_, data){
//         if (this.returnData.success
//             && this.returnData.route === 'auth'
//             && this.returnData.method ==='login'){
//             localStorage.hash = this.returnData.payload.hash;
//             this.$store.state.socket.loginStatus = true;
//             this.$router.push({ path: `/dashboard` });
//         }
//     },
//
// };

const mutations = {
    SOCKET_ONOPEN (state, event)  {
        Vue.prototype.$socket = event.currentTarget
        state.isConnected = true
    },
    // eslint-disable-next-line no-unused-vars
    SOCKET_ONCLOSE (state, event)  {
        state.isConnected = false
        location.reload();
    },
    SOCKET_ONERROR (state, event)  {
        console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, event)  {
        console.log(state, event)
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
        console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
        state.socket.reconnectError = true;
    },
}
const socket = {
    state,
    mutations,
};

export default socket
