<template>
  <section>
    <div class="wrap">
      <h1 class="page_title"><img src="@/assets/images/tab2_b.png">카지노 충환전 목록</h1>
      <div class="table_date">
        <div>
          <input type="date" v-model="payload.startDate">
          <input type="date" v-model="payload.endDate">
          <button @click="reqData(payload)">조회</button>
        </div>

      </div>
      <div class="table_layout">
        <table>
          <thead>
          <tr>
            <th rowspan="2" width="40">번호</th>
            <th rowspan="2">구분</th>
            <th>닉네임</th>
            <th rowspan="2">금액</th>
            <th>신청일시</th>
            <th rowspan="2">상태</th>
          </tr>
          <tr>
            <th>아이디</th>
            <th>처리일시</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in lists" :key="'deposit-list' + row.cash_seq">
            <td class="tc">{{ index | makeIndex(payload.page, payload.size) }}</td>
            <td class="tc"
              :class="{
                'b_color': row.cash_amount > 0,
                'r_color': row.cash_amount < 0,
              }"
            >{{ row.cash_type === '출금' ? '크레딧충전' : '크레딧환전' }}</td>
            <td>
              <div class="side">
                <span :class="'lv'+row.member.members_grade">Lv{{row.member.members_grade}}</span>
                {{ row.member.members_nickname }}
              </div>
              <hr>
              <p>
                <span>{{ row.member.members_id }}</span>
              </p>
            </td>
            <td :class="{
              'b_color': row.cash_amount > 0,
              'r_color': row.cash_amount < 0,
              }">{{ row.cash_amount | makeComma }}원</td>
            <td>
              <p>
                <span>{{ row.cash_regdatetime |  formatDate('YYYY-MM-DD HH:mm:ss') }}</span>
              </p>
              <hr>
              <p>
                <span>{{ row.cash_done_datetime |  formatDate('YYYY-MM-DD HH:mm:ss') }}</span>
              </p>
            </td>
            <td class="tc">{{ row.cash_status }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <Pagination :payload="payload" :req-data="reqData" :pagination="pagination"></Pagination>

    </div>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination';

export default {
  name: "Casino",
  components: {
    Pagination
  },
  data: function(){
    return {
      path: 'partner/balance/list',
      pagination: {},
      lists: [],
      payload: {
        page: 1,
        size:20,
        keyword: null,
        startDate: null,
        endDate: null,
        status: null,
        detail_type: '카지노'
      }
    }
  },
  mounted: function() {
    this.reqData(this.payload);
  },
  methods: {
    reqData: function(payload){
      this.$store.dispatch('auth/post', { path: this.path, data: { payload } })
          .then(e => {
            const data = e.data;
            this.lists = data.payload.list;
            this.pagination = data.payload.pagination
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
  },

}
</script>

<style scoped>

</style>
