<template>
  <div class="table_page_btn" v-if="pagination.end">
    <a @click="movePage(1)"><i class="fas fa-angle-double-left"></i></a>
    <a @click="movePage(payload.page - 10)"><i class="fas fa-angle-left"></i></a>
    <a v-for="row in lastPage" :key="'pagination'+row" @click="movePage(row)" :class="{'now': payload.page === row}">{{ row }}</a>
    <a @click="movePage(payload.page + 10)"><i class="fas fa-angle-right"></i></a>
    <a @click="movePage(pagination.end)"><i class="fas fa-angle-double-right"></i></a>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ['pagination', 'payload', 'reqData'],
  computed: {
    lastPage(){
      if (this.pagination.end < this.payload.size) return this.pagination.end;
      return this.payload.page + 10;
    }
  },
  methods: {
    movePage: function (data){
      this.payload.page = data;
      this.reqData(this.payload);
    },
  },

}
</script>

<style scoped>
</style>
