<template>
  <section>
    <div class="wrap">
      <h1 class="page_title"><img src="@/assets/images/tab1_b.png">종합 통계</h1>
      <div class="table_date">
        <div>
<!--          <button>이전달</button>-->
          <input type="date" v-model="payload.startDate">
          <input type="date"  v-model="payload.endDate">
<!--          <button>다음달</button>-->
          <button @click="reqData(payload)">조회</button>
        </div>
      </div>
      <div class="table_layout">
        <table>
          <thead>
            <tr>
              <th rowspan="2">날짜</th>
              <th rowspan="2">당일가입</th>
              <th>입금</th>
              <th rowspan="2">입출수익</th>
              <th>베팅금</th>
              <th rowspan="2">베팅수익</th>
<!--              <th rowspan="2">마일리지 환전</th>-->
            </tr>
            <tr>
              <th>출금</th>
              <th>당첨</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in dates" :key="'calcTerm'+index">
              <td>{{ row }}</td>
              <td>
                <div class="side">
                  <span class="join_td">가입</span>
                  {{todaySignUp(row)}}명
                </div>
              </td>
              <td>
                <p>
                  <span class="b_color">{{ todayDepWit(row).totDeposit | makeComma }}원</span>
                </p>
                <hr>
                <p>
                  <span class="r_color">{{ todayDepWit(row).totWithdrawal | makeComma }}원</span>
                </p>
              </td>
              <td class="r_color">{{ todayDepWit(row).totDeposit + todayDepWit(row).totWithdrawal | makeComma }}원</td>
              <td>
                <p>
                  <span>{{ todayBetting(row) * -1 | makeComma }}원</span>
                </p>
                <hr>
                <p>
                  <span>{{ todayPrize(row) | makeComma }}원</span>
                </p>
              </td>
              <td>{{ (todayBetting(row) * -1) - todayPrize(row) | makeComma}}원</td>
<!--              <td>5,670,000원</td>-->
            </tr>

            <tr class="total">
            <td>합계</td>
            <td>
              <div class="side">
                <span class="join_td">가입</span>
                1명
              </div>
            </td>
            <td>
              <p>
                <span class="b_color">{{ totalDeposit() | makeComma }}원</span>
              </p>
              <hr>
              <p>
                <span class="r_color">{{ totalWithdrawal() | makeComma }}원</span>
              </p>
            </td>
            <td :class="{
              'r_color': totalDeposit() + totalWithdrawal() < 0,
              'b_color': totalDeposit() + totalWithdrawal() > 0,
            }"
            >{{ totalDeposit() + totalWithdrawal() | makeComma }}원</td>
            <td>
              <p>
                <span>{{ totalBetting() | makeComma }}원</span>
              </p>
              <hr>
              <p>
                <span>{{ totalPrize() | makeComma }}원</span>
              </p>
            </td>
            <td>{{ totalBetting() - totalPrize() | makeComma }}원</td>
<!--            <td>5,670,000원</td>-->
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';

export default {
  name: "Term",
  data :function() {
    return {
      path: 'partner/calc/term',
      currentYear: 0,
      currentMonth: 0,
      year: 0,
      month: 0,
      lastMonthStart: 0,
      nextMonthStart: 0,
      today: 0,
      dates: [],
      members: [],
      payload: {
        startDate: null,
        endDate: null,
      },
      lists: []
    }
  },
  created: function(){
    this.payload.startDate = moment().format('YYYY-MM-01');
    this.payload.endDate = moment().format('YYYY-MM-DD');
    console.log(this.payload)
    this.getSetDays(this.payload.startDate, this.payload.endDate);
    this.reqData(this.payload)
  },
  methods: {
    reqData: function(){
      this.getSetDays(this.payload.startDate, this.payload.endDate);
      this.$store.dispatch('auth/post', { path: this.path, data: { payload: this.payload } })
          .then(e => {
            const data = e.data;
            this.lists = data.payload.list;
            this.members = data.payload.members;
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    // 처음일 마지막날구하기
    getSetDays: function(startDate, endDate) {
      const sYear = startDate.split('-')[0];
      const sMonth = startDate.split('-')[1]
      const sDay = startDate.split('-')[2];
      const eMonth = endDate.split('-')[1];
      const eDay = endDate.split('-')[2];
      if (sMonth !== eMonth) return alert('같은 달의 일자만 조회가 가능합니다.');
      if (Number(sDay) > Number(eDay)) return alert('시작일이 종료일보다 작습니다.');
      this.dates = [];
      for (let i = 1; i <= Number(eDay); i++){
        this.dates.push(`${sYear}-${sMonth}-${String(i).length === 1 ? `0${i}` : i}`)
      }
    },
    todaySignUp: function (row){
      const members = this.members.filter(e => e.members_regdatetime.indexOf(row) >= 0 && e.members_status==='정상' && e.members_type==='회원');
      return members.length;
    },
    todayDepWit: function (row){
      if (this.lists.length > 0){
        const cashToday = this.lists.filter(e => e.cash_regdatetime.indexOf(row) >= 0 && e.cash_detail_type === '캐시' && e.cash_status === '처리완료');
        const cashDeposit = cashToday.filter(e => e.cash_type === '입금');
        const cashWithdrawal = cashToday.filter(e => e.cash_type === '출금');
        const totDeposit = cashDeposit.reduce((prev, next) => prev + next.cash_amount, 0);
        const totWithdrawal = cashWithdrawal.reduce((prev, next) => prev + next.cash_amount, 0);
        return { totDeposit, totWithdrawal };
      } else {
        return { totDeposit: 0, totWithdrawal: 0 };
      }
    },
    todayBetting: function (row){
      if (this.lists.length > 0){
        // console.log(this.lists)
        const bettingToday = this.lists.filter(e => e.cash_regdatetime.indexOf(row) >= 0 && e.cash_detail_type === '베팅');
        const withdrawal = bettingToday.map(e => e.cash_amount);

        let totWithdrawal = 0;
        if (withdrawal.length !== 0) {
          totWithdrawal = withdrawal.reduce((prev, next) => prev + next);
        }
        return totWithdrawal;
      } else {
        return 0
      }

    },
    todayPrize: function (row){
      if (this.lists.length > 0) {
        const bettingToday = this.lists.filter(e => e.cash_regdatetime.indexOf(row) >= 0 && e.cash_detail_type === '당첨');
        const withdrawal = bettingToday.map(e => e.cash_amount);

        let totWithdrawal = 0;
        if (withdrawal.length !== 0) {
          totWithdrawal = withdrawal.reduce((prev, next) => prev + next);
        }
        return totWithdrawal;
      } else {
        return 0
      }

    },
    totalBetting: function (){
      const bettingToday = this.lists.filter(e => e.cash_detail_type === '베팅');
      const withdrawal = bettingToday.map(e => e.cash_amount);

      let totWithdrawal = 0;
      if (withdrawal.length !== 0) {
        totWithdrawal = withdrawal.reduce((prev, next) => prev + next);
      }
      return totWithdrawal * -1;
    },
    totalPrize: function (){
      const bettingToday = this.lists.filter(e => e.cash_detail_type === '당첨');
      const withdrawal = bettingToday.map(e => e.cash_amount);

      let totWithdrawal = 0;
      if (withdrawal.length !== 0) {
        totWithdrawal = withdrawal.reduce((prev, next) => prev + next);
      }
      return totWithdrawal;
    },
    totalDeposit: function(){
      const signupDeposit = this.lists.filter(e => e.cash_type==='입금' && e.cash_detail_type === '캐시');
      const amounts = signupDeposit.map(e => e.cash_amount);
      if (amounts.length === 0){
        return 0;
      }
      const total = amounts.reduce((prev, next) => prev + next);
      return total;
    },
    totalWithdrawal: function(){
      const signupDeposit = this.lists.filter(e => e.cash_type==='출금' && e.cash_detail_type === '캐시');
      const amounts = signupDeposit.map(e => e.cash_amount);
      if (amounts.length === 0){
        return 0;
      }
      const total = amounts.reduce((prev, next) => prev + next);
      return total;
    },
  }
}
</script>

<style scoped>

</style>
