<template>
  <div class="sc-7b0c5f9a-0 dUfFMo">
    <div class="sc-7b0c5f9a-1 cAcwak">
        <div class="sc-7b0c5f9a-2 juNtrL">
          <h1>
            <!-- <img src="@/assets/images/main_toplogo.png" alt="굿벳"> -->
          </h1>
            <section>
              <label>아이디</label><input placeholder="아이디를 입력하세요." name="name" v-model="msg.payload.userId">
              <label>비밀번호</label><input type="password" placeholder="비밀번호를 입력하세요." name="password" v-model="msg.payload.userPw">
                <button class="sc-e6643d98-0 iTcjEq btn" @click="doLogin">로그인</button>
                <p>거래소<!-- --> Web Developer Team 2023</p>
            </section>
        </div>
    </div>
  </div>
  </template>

<script>
export default {
  name: "Login",
  data: () => ({
    msg: {
      route:'login',
      payload: {
        userId: '',
        userPw: '',
        sitename: 'exchange'
      },
    },
  }),
  methods: {
    doLogin: function() {
      this.$store.dispatch('auth/login', this.msg.payload )
          .then(e => {
            console.log(e);
            if (e.payload.token) {
              console.log(e.payload);
              localStorage.setItem('token', JSON.stringify(e.payload.token));
              this.$store.dispatch('auth/setUser', e.payload.members)
              this.$router.push('/member/list')
            }
          }).catch(err => {
        alert(err);
      })
    },
  },

}
</script>

<style scoped>
.dUfFMo {
    width: 100%;
    height: 100%;
    background:  url(./assets/images/bg.png);;
}
.cAcwak {
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: var(--background-login-overlay);
}
.juNtrL {
    position: relative;
    width: 430px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: var(--shadow-box-x-large);
    overflow: hidden;
}
.juNtrL h1 {
    width: 100%;
    height: 65px;
    text-align: center;
    background-color: var(--background-login-header);
}

.juNtrL section {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    background: var(--white-color);
}

.juNtrL h1 img {
  display: inline-block;
  margin-top: 2px;
  width: 220px;
  height: 60px;
}

.juNtrL label {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
}

.juNtrL input {
  margin-bottom: 25px;
  padding: 0px 10px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: 1px solid var(--input-default-border-color);
  font-size: 15px;
  box-sizing: border-box;
}

.iTcjEq {
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-style: normal;
    padding: 0px 10px;
    height: 50px;
    font-size: 17px;
    background: var(--white-color);
    border: 1px solid #ffae00;
    color: var(--button-default-text-color);
}

.iTcjEq .btn{
  background: #ffae00;
  border-color: #ffae00;
  color: #ffffff;
}

.juNtrL p {
    font-size: 12px;
    margin-top: 25px;
    text-align: center;
}
</style>
